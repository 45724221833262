import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const CancellationPolicyPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Payment Methods
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl mb-4"><b>Payment Methods</b></h2>
            <p>Flexible payment options are important when renting a car. To confirm your booking and rental, we will send you an e-mail request for a 100% deposit of the total amount up to one week prior to booking.</p>
            <p>We accept the following payment methods for your convenience:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>Credit Card Payment: We accept most major credit cards, including Visa, Mastercard, and American Express. Credit card payments offer a convenient and secure way to pay for your rental.</li>
              <li>Deposit in Bank Account: If you prefer, you may also deposit the total amount into our bank account. We will provide you with the necessary bank details to complete the transaction.</li>
              <li>Cash Payment: We also accept cash payments for your rental. </li>
            </ul>
            <p className="mt-4">
              We are committed to providing you with easy and secure payment options. Book your rental car with confidence and enjoy your trip!
            </p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Payment Methods | Cool Car Rentals"
    description="Secure and Flexible Payment Methods for Car Rentals | Accepting Credit Cards, Bank Deposits, and Cash Payments | Book with Confidence Today!"
  />
);

export default CancellationPolicyPage;